
import { defineComponent } from 'vue'
import { useMainStore } from "@/stores/main";
import axios from 'axios';

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() }
  },
  data() {
    return {
      email: "",
      password: "",
      is_logging: false
    }
  },
  validations () {
    return {
      email: { required, email }, 
      password: { required }
    }
  },
  methods: {
    async login(e: any) {
      e.preventDefault()
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.is_logging = true;
      const main = useMainStore()
      await axios
        .post('/auth/login', {
          username: this.email,
          password: this.password
        })
        .then((response: any) => {
          main.setUser(response.data);
          setTimeout(() => {
            this.$router.push({name: "user_forms"});
          }, 1);
          this.is_logging = false;
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
          this.is_logging = false;
        })
    }
  }
})

import { defineStore } from 'pinia'

export type User = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  permissions: string[];
};

var user: User;

export const useMainStore = defineStore('main', {
  state: () => ({
    user,
  }),
  getters: {},
  actions: {
    setUser(user: any) {
      this.user = user;
    },
  },
})


import { defineComponent } from 'vue'
import { useMainStore } from "@/stores/main";
import axios from 'axios';

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() }
  },
  data() {
    return {
      email: "",
      completed: false
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  },
  methods: {
    async resetPassword(e: any) {
      e.preventDefault()
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      const main = useMainStore()
      axios
        .post('/auth/password/reset', {
          email: this.email
        })
        .then((response: any) => {
          this.completed = true;
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
        })
    }
  }
})

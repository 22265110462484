
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    rows!: Array,
    columns!: Array,
    total!: Number
  },

  emits: ["pageChanged", "sortChanged"],

  data() {
    let pages: number[] = []
    return {
      page: 1,
      sort: {
        field: '',
        asc: true
      },
      pages,
      perPage: 10
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    clickedPage(page: number) {
      this.page = page;
      this.$emit('pageChanged', page);
    },
    sortTable(field: string) {
      this.sort.field = field;
      this.sort.asc = !this.sort.asc;
      this.$emit('sortChanged', this.sort);
    },
    getFieldData(item: any, field: any) {
      if (field["type"] == "date") {
        var date = new Date(item[field["key"]]);
        return date.toLocaleDateString('en-US', {
          weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
        });
      } else if (field["type"] == "full_name") {
        return item["first_name"] + ' ' + item["last_name"];
      }
      return item[field["key"]];
    },
    async refresh() {
      await this.$nextTick();

      let numberOfPages = Math.ceil(this.total! / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    }
  }
});

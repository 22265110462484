
import { defineComponent } from 'vue'
import axios from 'axios';

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate({ $lazy: true }) }
  },
  data() {
    return {
      password: "",
      confirmation: ""
    }
  },
  validations () {
    const self = this;
    return {
      password: { required },
      confirmation: {
        required: helpers.withMessage('Please confirm your password.', required),
        serverValidation: {
          $message() {
            return 'Password mismatch';
          },
          $validator() {
            return self.password == self.confirmation;
          },
        },
        $autoDirty: true,
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!to.query.token) {
      next({ name: 'login' })
    } else {
      next()
    }
  },
  methods: {
    async login(e: any) {
        e.preventDefault()
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      axios
        .post('/auth/password/reset/confirm', {
          token: this.$route.query.token,
          password: this.password
        })
        .then((response: any) => {
            this.toast.success("Password updated successfully");
          this.$router.push({name: "login"});
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
        })
    }
  }
})


import { defineComponent } from 'vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import IMask, { InputMask, AnyMaskedOptions } from 'imask';
var phone: InputMask<AnyMaskedOptions>;

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate({ $lazy: true }) }
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      numberModel: '',
      phone,
      errors: {}
    }
  },
  validations() {
    const self = this;
    return {
      first_name: { required }, 
      last_name: { required },
      company: { required },
      email: {
        required: helpers.withMessage('Please enter an email address.', required),
        email,
        serverValidation: {
          $message() {
            // `this` is not yet available in latest release
            return (self.errors as any)["email"][0];
          },
          $validator() {
            // `this` is not yet available in latest release
            return !(self.errors as any)["email"];
          },
        },
        $autoDirty: true,
      }
    }
  },
  mounted() {
    var maskOptions = {
      mask: '+1 (000) 000-0000'
    };
    this.phone = IMask(this.$refs['phone'] as HTMLElement, maskOptions);
  },
  methods: {
    async addUser(event: any) {
      const result = await this.v$.$validate()
      if (!result) {
        return;
      }

      event.preventDefault()
      axios
        .post('/users/', {
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone.unmaskedValue,
          company: this.company,
          email: this.email
        })
        .then((response: any) => {
          this.$router.push({name: "users"});
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else if (error.response.data['detail']){
            this.toast.error("Something Went Wrong Please Try Again");
          } else {
            this.errors = error.response.data;
            this.v$.$reset();
            this.v$.$touch();
          }
        })
    },
    reset(field: any){
      delete (this.errors as any)[field];
    }
  }
})

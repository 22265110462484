
import { defineComponent } from 'vue';
import axios from 'axios';

import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import IMask, { InputMask, AnyMaskedOptions } from 'imask';
import { useToast } from "vue-toastification";

var phone: InputMask<AnyMaskedOptions>;

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate({ $lazy: true }) }
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      numberModel: '',
      phone,
      company: "",
      errors: {},
      password: "",
      current_password: ""
    }
  },
  validations () {
    const self = this;
    return {
      password: { required },
      current_password: { required }
    }
  },
  mounted() {
    var maskOptions = {
      mask: '+1 (000) 000-0000'
    };
    this.phone = IMask(this.$refs['phone'] as HTMLElement, maskOptions);

    this.getUser();
  },
  methods: {
    getUser() {
      axios
        .get('/user/info')
        .then((response: any) => {
          this.first_name = response.data['first_name'];
          this.last_name = response.data['last_name'];
          this.email = response.data['email'];
          this.phone.unmaskedValue = response.data['phone'];
          this.company = response.data['company'];
        })
    },
    updatePhone() {
      axios
        .patch('/user/info', {
          phone: this.phone.unmaskedValue,
          company: this.company
        })
        .then((response: any) => {
          this.first_name = response.data['first_name'];
          this.last_name = response.data['last_name'];
          this.email = response.data['email'];
          this.phone.unmaskedValue = response.data['phone'];
          this.company = response.data['company'];
          this.toast.success(`Profile has been updated`)
        })
    },
    async changePassword(event: any) {
      event.preventDefault()
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      axios
        .patch('/auth/change/password', {
          password: this.password,
          current_password: this.current_password
        })
        .then((response: any) => {
          this.toast.success(`Password has been updated`)
        })
      this.password = "";
      this.current_password = "";
    }
  }
})


import { defineComponent } from 'vue';
import { useMainStore } from "@/stores/main";
import axios from 'axios';

export default defineComponent({
    setup() {
        const main = useMainStore()
        return {
            main
        }
    },
    methods: {
        logout() {
            axios
            .post('/auth/logout')
            .then((response: any) => {
                this.main.setUser(undefined);
                this.$router.push({'name': 'login'})
            })
        },
        is_admin() {
          return this.main.user?.permissions?.includes("is_admin");
        }
    }
})

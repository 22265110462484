
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  data() {
    return {
      columns: [
        // {
        //   title: 'ID',
        //   key: 'id'
        // },
        {
          type: 'full_name',
          title: 'Full Name'
        },
        {
          key: 'email',
          title: 'Email'
        },
        {
          key: 'phone',
          title: 'Phone'
        },
        {
          key: 'company',
          title: 'Company'
        },
        {
          key: 'id',
          type: 'button',
          title: '',
          actions: [
            {
              class: 'btn-secondary',
              title: 'View User',
              action: (id: any) => {
                this.$router.push({ name: "user", params: { id: id } })
              }
            }
          ]
        },
      ],
      rows: [],
      total: 0,
      page: 1
    }
  },
  mounted() {
    this.getForms()
  },
  methods: {
    getForms() {
      axios
        .get('/users?page=' + this.page)
        .then((response: any) => {
            this.rows = response.data.results;
            this.total = response.data.count;

            (this.$refs['table'] as any).refresh();
        })
        .catch((error: any) => console.log(error))
    },
    pageChanged(page: number) {
      this.page = page;
      this.getForms();
    }
  }
})


import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
    data() {
        return {
            columns: [
                // {
                //   title: 'ID',
                //   key: 'id'
                // },
                {
                    key: "created_at",
                    type: "date",
                    title: "Created At"
                },
                {
                    key: "name",
                    title: "Name"
                },
                {
                    key: "id",
                    type: "button",
                    title: "Form",
                    actions: [
                      {
                        title: "Submit Form",
                        class: 'btn-secondary',
                        action: (id: any) => {
                            this.$router.push({ name: "user_form", params: { id: id } });
                        }
                      }
                    ]
                },
            ],
            rows: [],
            total: 0,
            page: 1
        };
    },
    mounted() {
        this.getForms();
    },
    methods: {
        getForms() {
            axios
                .get("/user/forms?page=" + this.page)
                .then((response: any) => {
                this.rows = response.data.results;
                this.total = response.data.count;
                (this.$refs["table"] as any).refresh();
            })
                .catch((error: any) => console.log(error));
        },
        pageChanged(page: number) {
            this.page = page;
            this.getForms();
        }
    }
})

import axios from 'axios';
import { useMainStore } from "@/stores/main";

async function IsAuthenticated(to: any) {
    const main = useMainStore()
    if (!main.user) {
        const instance = axios.create();
        await instance.post('/auth/session')
        .then((response: any) => {
            if (response) {
                main.setUser(response.data);
            }
        })
        .catch((error) => {
            if (error.response) {
                
            }
            return;
        });
        return;
    }
}

async function IsAdmin(to: any) {
    const main = useMainStore()
    if (main.user) {
        if (main.user.permissions.includes("is_admin")) {
            return;
        }
    }
    return { name: "user_forms" }
}

async function IsLogged(to: any) {
    const main = useMainStore()
    if (main.user) {
        if (main.user.permissions.includes("is_admin")) {
            return { name: "forms" };
        }
        return { name: "user_forms" }
    }
    return;
}

export {
    IsAuthenticated,
    IsLogged,
    IsAdmin,
}

import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  data() {
    return {
      columns: [
        {
          key: 'created_at',
          type: 'date',
          title: 'Added On',
          sortable: true
        },
        {
          key: 'name',
          title: 'Name'
        },
        {
          key: 'id',
          type: 'button',
          title: 'Form',
          actions: [
            {
              title: 'Edit Form',
              class: 'btn-secondary',
              action: (id: any) => {
                this.$router.push({ name: "editForm", params: { id: id } })
              }
            },
            {
              title: 'Preview Form',
              class: 'btn-dark',
              action: (id: any) => {
                this.$router.push({ name: "form", params: { id: id } })
              }
            }
          ]
        },
      ],
      rows: [],
      total: 0,
      page: 1,
      sort: {
        field: 'date',
        asc: true
      }
    }
  },
  mounted() {
    this.getForms()
  },
  methods: {
    getForms() {

      axios
        .get(`/forms?page=${this.page}&ordering=${ this.sort.asc === false ? "-" : "" }${this.sort.field}`)
        .then((response: any) => {
            this.rows = response.data.results;
            this.total = response.data.count;

            (this.$refs['table'] as any).refresh();
        })
        .catch((error: any) => console.log(error))
    },
    pageChanged(page: number) {
      this.page = page;
      this.getForms();
    },
    sortChanged(sort: any) {
      this.sort = sort;
      this.getForms();
    }
  }
})

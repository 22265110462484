import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import HomeView from "../views/HomeView.vue";

import AuthView from "../views/AuthView.vue";
import LoginView from "../views/LoginView.vue";
import PasswordResetView from "../views/PasswordResetView.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ProfileView from "../views/general/ProfileView.vue";

import UserLayout from "../views/user/UserView.vue";
import UserFormsView from "../views/user/Forms/FormsView.vue";

import AdminLayout from "../views/admin/AdminView.vue";
import FormsView from "../views/admin/Forms/FormsView.vue";
import AddFormView from "../views/admin/Forms/AddFormView.vue";
import EditFormView from "../views/admin/Forms/EditFormView.vue";
import FormView from "../views/admin/Forms/FormView.vue";
import UsersView from "../views/admin/Users/UsersView.vue";
import UserView from "../views/admin/Users/UserView.vue";
import AddUserFormView from "../views/admin/Users/AddUserFormView.vue";
import CreateUserView from "../views/admin/Users/CreateUserView.vue"

import { IsAuthenticated, IsLogged, IsAdmin } from "./guards/guards"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: [IsAuthenticated],
    meta: { title: 'Home' }
  },
  {
    path: "/auth",
    component: AuthView,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginView,
        beforeEnter: [IsAuthenticated, IsLogged],
        meta: { title: 'Login' }
      },
      {
        path: "password/reset",
        name: "passwordReset",
        component: PasswordResetView,
        meta: { title: 'Password Reset' }
      },
      {
        path: "forgot/password",
        name: "forgotPassword",
        component: ForgotPassword,
        meta: { title: 'Forgot Password' }
      },
    ]
  },
  {
    path: "/admin",
    component: AdminLayout,
    beforeEnter: [IsAuthenticated, IsAdmin],
    children: [
      {
        path: "forms",
        name: "forms",
        component: FormsView,
        meta: { title: 'Forms' }
      },
      {
        path: "forms/add",
        name: "addForm",
        component: AddFormView,
        meta: { title: 'Add Form' }
      },
      {
        path: "forms/:id",
        name: "form",
        component: FormView,
        meta: { title: 'Form' }
      },
      {
        path: "forms/:id/edit",
        name: "editForm",
        component: EditFormView,
        meta: { title: 'Form' }
      },
      {
        path: "users",
        name: "users",
        component: UsersView,
        meta: { title: 'Users' }
      },
      {
        path: "users/new",
        name: "newUser",
        component: CreateUserView,
        meta: { title: 'Create User' }
      },
      {
        path: "users/:id",
        name: "user",
        component: UserView,
        meta: { title: 'User' }
      },
      {
        path: "users/:id/form/add",
        name: "addUserForm",
        component: AddUserFormView,
        meta: { title: 'Add User' }
      },
      { 
        path: "",
        redirect: {
          name: "forms"
        }
      }
    ]
  },
  {
    path: "/user",
    component: UserLayout,
    beforeEnter: [IsAuthenticated],
    children: [
      {
        path: "forms",
        name: "user_forms",
        component: UserFormsView,
        meta: { title: 'Forms' }
      },
      {
        path: "forms/:id",
        name: "user_form",
        component: FormView,
        meta: { title: 'Form' }
      },
      {
        path: "profile",
        name: "user_profile",
        component: ProfileView,
        meta: { title: 'Profile' }
      },
      { 
        path: "",
        redirect: {
          name: "user_forms"
        }
      }
    ]
  },
  { 
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "login"
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;


import { defineComponent } from 'vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, url } from '@vuelidate/validators'
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() }
  },
  data() {
    return {
      name: "",
      link: ""
    }
  },
  validations () {
    return {
      name: { required }, 
      link: { required, url }
    }
  },
  methods: {
    async addForm(event: any) {
      const result = await this.v$.$validate()
      if (!result) {
        return;
      }

      event.preventDefault()
      axios
        .post('/forms/', {
          name: this.name,
          link: this.link
        })
        .then((response: any) => {
          this.$router.push({name: "forms"});
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
        })
    }
  }
})

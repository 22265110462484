
import { defineComponent } from 'vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: "",
        link: ""
      }
    }
  },
  validations () {
    return {
      form: {
        name: { required }, 
        link: { required, url }
      }
    }
  },
  mounted() {
    this.getForm()
  },
  methods: {
    getForm() {
      axios
        .get('/forms/' + this.$route.params.id)
        .then((response: any) => {
            this.form = response.data;
            this.toast.success(`${this.form.name} has been updated`)
        })
        .catch((error: any) => console.log(error))
    },
    async addForm(event: any) {
      const result = await this.v$.$validate()
      if (!result) {
        return;
      }

      event.preventDefault()
      axios
        .patch(`/forms/${this.$route.params.id}`, this.form)
        .then((response: any) => {
          this.$router.push({name: "forms"});
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
        })
    },
    async deleteForm(event: any) {
      event.preventDefault()
      axios
        .delete(`/forms/${this.$route.params.id}`)
        .then((response: any) => {
          this.$router.push({name: "forms"});
          this.toast.warning(`${this.form.name} has been deleted`)
        })
        .catch((error: any) => {
          if (error.response.data['non_field_errors']) {
            this.toast.error(error.response.data['non_field_errors'][0]);
          } else {
            this.toast.error("Something Went Wrong Please Try Again");
          }
        })
    }
  }
})


import { defineComponent } from 'vue';
import { useMainStore } from "@/stores/main";
import mapboxgl from 'mapbox-gl';

export default defineComponent({
  setup() {
    const main = useMainStore()
    return {
      main
    }
  },
  mounted() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidmEycm9uMSIsImEiOiJjazlzbDBram0wMDdnM2tua2QyZnZsa2pmIn0.AOiO0at1NncLOvlm0uxGug';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-66.324432, 18.343538],
      zoom: 16,
      pitch: 45,
      bearing: 0,
      antialias: true,
      interactive: false
    });

    new mapboxgl.Popup({ closeButton: false,closeOnClick: false })
    .setLngLat([-66.324805,18.343616])
    .setHTML('<h6 style="color: #aaa">Contact Info:</h6><p class="p-0 m-0">Sonnell Fleet Solutions II<br/>(787) 859-0302<br/>PO Box 1063<br/>Corozal PR 00783</p>')
    .addTo(map);
  },
  methods: {
    scrollToElement(element: string) {
      const el = this.$refs[element];
      if (el) {
        (el as HTMLElement).scrollIntoView({ behavior: "smooth" });
      }
    }
  }
})

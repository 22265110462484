import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import router from "./router";

import VueCookies from "vue-cookies";

import CTable from '@/components/CTable.vue';

import axios, {
    AxiosError,
} from 'axios';

import Toast, { PluginOptions, POSITION } from "vue-toastification";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import 'mapbox-gl/dist/mapbox-gl.css';
import "vue-toastification/dist/index.css";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response?.status == 401 || error.response?.status == 403) {
            router.push({'name': 'login'})
        }
        return Promise.reject(error);
    }
);
const pinia = createPinia()

const options: PluginOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};

router.afterEach((to, from) => {
    document.title = `${to.meta.title} | SFSII`;
});

const app = createApp(App)
    .use(router)
    .use(pinia)
    .use(VueCookies)
    .use(Toast, options)
    .component('ctable', CTable)

app.config.globalProperties.$filters = {
    capitalising(value: string) {
        if (value) {
            return value[0].toUpperCase() + value.substring(1);
        }
        return value;
    }
}

app.mount("#app");
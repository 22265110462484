
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  data() {
    return {
      form: {
        name: "",
        link: ""
      },
      eform: ""
    }
  },
  mounted() {
    this.getForm()
  },
  methods: {
    getForm() {
      axios
        .get('/forms/' + this.$route.params.id)
        .then((response: any) => {
            this.form = response.data;
            this.validateUrl(this.form.link);
        })
        .catch((error: any) => console.log(error))
    },
    validateUrl(url: any) {
      const eform = new URL(url);
      const params = new URLSearchParams(eform.search);
      if (!params.has('allowanonymous')) {
        params.set('allowanonymous', '1');
      }
      this.eform = `${eform.origin}${eform.pathname}${eform.hash}?${params}`;
    },
    oneFormLoaded(event: any) {
      // console.log(event)
      // console.log(event.contentWindow.location)
      // console.log(window.document.querySelectorAll("app-data-entry-view .formio-component")[0])
    },
    goBack(event: any) {
      if (this.$router.currentRoute.value.fullPath.startsWith('/admin')) {
        this.$router.push({ name: 'forms' });
      } else {
        this.$router.push({ name: 'user_forms' });
      }
    }
  }
})


import { defineComponent } from 'vue';
import axios from 'axios';
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast }
  },
  data() {
    return {
      full_name: "",
      columns: [
        {
          key: 'created_at',
          type: 'date',
          title: 'Added On'
        },
        {
          key: 'name',
          title: 'Name'
        },
        {
          key: 'id',
          type: 'button',
          title: 'Form',
          actions: [
            {
              class: 'btn-secondary',
              title: '<i class="bi bi-plus-lg"></i> Select',
              action: (id: any) => {
                this.action(id);
              }
            }
          ]
        },
      ],
      rows: [],
      total: 0,
      page: 1,
      user: {
        first_name: "",
        last_name: ""
      }
    }
  },
  mounted() {
    axios
      .get('/users/' + this.$route.params.id)
      .then((response: any) => {
          this.user = response.data;
          this.getForms();
      })
      .catch((error: any) => console.log(error))
  },
  methods: {
    getForms() {
      axios
        .get(`/users/${this.$route.params.id}/forms/filter?page=` + this.page)
        .then((response: any) => {
            this.rows = response.data.results;
            this.total = response.data.count;

            (this.$refs['table'] as any).refresh();
        })
        .catch((error: any) => console.log(error))
    },
    pageChanged(page: number) {
      this.page = page;
      this.getForms();
    },
    action(id: any) {
      axios
        .post(`/users/${this.$route.params.id}/forms/${id}`, {})
        .then((response: any) => {
          this.toast.success('Form has been added');
          this.$router.push({name: "user", params: { id: this.$route.params.id }});
        })
        .catch((error: any) => {
          // if (error.response.data['non_field_errors']) {
          //   this.msg = error.response.data['non_field_errors'][0];
          // } else {
          //   this.msg = "Something Went Wrong Please Try Again"
          // }
        })
    }
  }
})
